import React, { useState } from 'react'

import Filters from '../../shared/Filters/Filters'
import { getCompaniesListNames } from '../../../helpers/companies'
import {
  activeChecks,
  filterArray,
  handleChecked
} from '../../../utils/filters'

const StoresFilters = ({ stores, handleFilterChange, isFiltered, setIsFiltered }) => {
  const companies = getCompaniesListNames()
  const [checkCompanies, setCheckCompanies] = useState(new Array(companies.length).fill(false))

  const handleCompanies = (position) => {
    handleChecked(position, checkCompanies, setCheckCompanies)
  }

  const fields = [
    {
      name: 'Empresa',
      checkList: companies,
      handleCheck: handleCompanies,
      checkState: checkCompanies
    }
  ]

  const handleSubmit = () => {
    let filteredData = stores
    filteredData = filterArray(filteredData, checkCompanies, companies, 'companyName')

    handleFilterChange(filteredData)
    setIsFiltered(activeChecks(checkCompanies))
  }

  const handleClean = () => {
    setCheckCompanies(new Array(companies.length).fill(false))
    setIsFiltered(false)
    handleFilterChange(stores)
  }

  return (
    <Filters
      fields={fields}
      handleSubmit={handleSubmit}
      handleClean={handleClean}
      isFiltered={isFiltered}
      allowSingle={1}
      atLeft={1}
    />
  )
}

export default StoresFilters
