import React from 'react'
import { Pie, PieChart, Tooltip } from 'recharts'

import PieToolTip from '../../../Dashboard/Charts/DonutPieChart/PieToolTip'

const StoreCapacityChart = ({ data, total }) => {
  if (total === 0) {
    return (
      <div className="flex my-2">
        <div className="mx-auto text-sm">No hay datos</div>
      </div>
    )
  }

  const percentage = total ? Math.round((data[0].value / total) * 100) : 0

  return (
    <PieChart width={320} height={150}>
      <Tooltip content={<PieToolTip total={total} />} cursor={false} />
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        innerRadius={40}
        outerRadius={60}
        fill="#8884d8"
        dataKey="value"
      />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dominantBaseline="middle"
        className="text-xl text-ultra-dark-grey"
      >
        {percentage}%
      </text>
    </PieChart>
  )
}

export default StoreCapacityChart
