import {
  FRIDAY,
  FRIDAY_CODE,
  MONDAY,
  MONDAY_CODE,
  SATURDAY,
  SATURDAY_CODE,
  SUNDAY,
  SUNDAY_CODE,
  THURSDAY,
  THURSDAY_CODE,
  TUESDAY,
  TUESDAY_CODE,
  WEDNESDAY,
  WEDNESDAY_CODE
} from './days'
import { COLORS } from './styles'

export const STORE_ACTIVE = 'active'
export const STORE_INACTIVE = 'inactive'
export const STORE_FULL = 'full'

export const STORE_STATUS_COLORS = {
  [STORE_ACTIVE]: {
    backgroundColor: COLORS.DARK_GREEN,
    textColor: COLORS.LIGHT_GREEN
  },
  [STORE_INACTIVE]: {
    backgroundColor: COLORS.RED,
    textColor: COLORS.WHITE
  },
  [STORE_FULL]: {
    backgroundColor: COLORS.YELLOW,
    textColor: COLORS.WHITE
  }
}

export const STORE_STATUS_TRANSLATE = {
  [STORE_ACTIVE]: 'Activa',
  [STORE_INACTIVE]: 'Inactiva',
  [STORE_FULL]: 'Llena'
}

export const STORE_ID_HEADER = 'ID'
export const STORE_NAME_HEADER = 'Nombre'
export const STORE_STATUS_HEADER = 'Estado'
export const STORE_STOCK_HEADER = 'Inventario'
export const STORE_REGION_HEADER = 'Región'

export const STORE_ID = 'id'
export const STORE_NAME = 'name'
export const STORE_STATUS = 'status'
export const STORE_STOCK = 'totalAvailableStock'
export const STORE_REGION = 'state'

export const DEFAULT_STORE_SCHEDULE = {
  open: true,
  startTime: '09:00',
  finishTime: '19:00'
}

export const DEFAULT_STORE_SCHEDULES = [
  {
    dayCode: MONDAY_CODE,
    day: MONDAY,
    open: true,
    startTime: DEFAULT_STORE_SCHEDULE.startTime,
    finishTime: DEFAULT_STORE_SCHEDULE.finishTime
  },
  {
    dayCode: TUESDAY_CODE,
    day: TUESDAY,
    open: true,
    startTime: DEFAULT_STORE_SCHEDULE.startTime,
    finishTime: DEFAULT_STORE_SCHEDULE.finishTime
  },
  {
    dayCode: WEDNESDAY_CODE,
    day: WEDNESDAY,
    open: true,
    startTime: DEFAULT_STORE_SCHEDULE.startTime,
    finishTime: DEFAULT_STORE_SCHEDULE.finishTime
  },
  {
    dayCode: THURSDAY_CODE,
    day: THURSDAY,
    open: true,
    startTime: DEFAULT_STORE_SCHEDULE.startTime,
    finishTime: DEFAULT_STORE_SCHEDULE.finishTime
  },
  {
    dayCode: FRIDAY_CODE,
    day: FRIDAY,
    open: true,
    startTime: DEFAULT_STORE_SCHEDULE.startTime,
    finishTime: DEFAULT_STORE_SCHEDULE.finishTime
  },
  {
    dayCode: SATURDAY_CODE,
    day: SATURDAY,
    open: false,
    startTime: DEFAULT_STORE_SCHEDULE.startTime,
    finishTime: DEFAULT_STORE_SCHEDULE.finishTime
  },
  {
    dayCode: SUNDAY_CODE,
    day: SUNDAY,
    open: false,
    startTime: DEFAULT_STORE_SCHEDULE.startTime,
    finishTime: DEFAULT_STORE_SCHEDULE.finishTime
  }
]
