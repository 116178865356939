const ROUTES = {
  DASHBOARD: '/e-commerce/dashboard',
  SALES: '/e-commerce/ventas',
  SALE: '/e-commerce/ventas/:saleId',
  SALEWITHERROR: '/e-commerce/ventas/con-gestion/:saleId',
  PACKING: '/e-commerce/ventas/packing',
  PICKING: '/e-commerce/ventas/picking',
  MULTIPACKAGE: '/e-commerce/ventas/multipaquete/:saleId',
  COURIERS: '/e-commerce/couriers',
  COURIER_SETTINGS: '/e-commerce/couriers/configuraciones',
  TRACEABILITY: '/e-commerce/trazabilidad',
  BILLING: '/e-commerce/facturacion',
  WAREHOUSES: '/pudos/bodegas',
  WAREHOUSE: '/pudos/bodegas/:warehouseId',
  PACKAGES: '/pudos/paquetes',
  FAQ: '/otros/FAQs',
  PROFILE: '/perfil',
  RECOVERPASS: '/pass/recuperar',
  RESTOREPASS: '/pass/restablecer',
  CROSSAPPROVAL: '/pudos/mispuntos',
  ORDERCREATION: '/e-commerce/creacion-de-orden',
  MASIVE_ORDER_CREATION: '/e-commerce/creacion-de-orden-masiva',
  PRICING: '/e-commerce/precios',
  PERSONALIZATION: '/personalizacion',
  PICKUP_APP: '/pickup-app',
  MULTIVENDE: '/multivende',
  MANIFEST: '/manifiesto',
  CREATE_MANIFEST: '/manifiesto/crear/:manifestId',
  TICKETS: '/solicitudes',
  TICKET: '/solicitudes/:ticketId',
  NEW_TICKET: '/solicitudes/crear/:pinflagId?',
  RULES: '/reglas',
  RULE: '/reglas/:ruleId',
  NEW_RULE: '/reglas/crear',
  INCIDENTS: '/incidencias',
  PERMISSIONS: '/permisos',
  PERMISSIONS_USER: '/permisos/usuario/:userId?',
  DISTRIBUTION: '/distribucion',
  DISTRIBUTION_PACKAGE: '/distribucion/paquete/:packageId',
  DISTRIBUTION_MANIFEST: '/distribucion/manifiesto/:manifestId',
  DISTRIBUTION_STORES: '/distribucion/tiendas/:storeId',
  GOOGLE_ANALYTICS: '/google-analytics',
  REFUNDS: '/indemnizaciones',
  REFUND: '/indemnizaciones/:refundId',
  CREATE_REFUND: '/indemnizaciones/crear/:packageId',
  ABANDONED_CARTS: '/e-commerce/carritos-abandonados',
  ABANDONED_CART: '/e-commerce/carritos-abandonados/:abandonedCartId',
  SCAN: '/escaneo',
  STOCK_CONFIG: '/stock/configuracion',
  STORES: '/tiendas',
  STORE: '/tiendas/:warehouseId',
  STORE_EDIT: '/tiendas/:warehouseId/configuracion'
}

export default ROUTES
