import React, { useState, useContext, useEffect, useRef } from 'react'

import { LOGOS, pointsActive, STOCKS_ICONS } from '../../../constants/icons'
import Button from '../../../components/shared/Button'
import StoreSearchButton from './StoreSearchButton'
import Modal from '../../../components/shared/Modal/Modal'
import { StockConfigurationContext } from '../../../contexts/StockConfigurationContext'
import usePostLocationMatches from '../../../components/Settings/Stock/usePostLocationMatches'
import RawLoader from '../../../components/shared/Loader/RawLoader'
import LoadingError from '../../../components/shared/LoadingError'


const StoreMatching = ({ onMatchingFinished }) => {
  const {
    pinflagStores,
    posStores,
    setPosStores,
    cmsStores,
    setCmsStores,
    storesLeft,
    setStoresLeft,
    selectedPinflagStore,
    setSelectedPinflagStore,
    locations,
    companyId
  } = useContext(StockConfigurationContext)

  const [selectedPosStore, setSelectedPosStore] = useState(null)
  const [selectedCmsStore, setSelectedCmsStore] = useState(null)
  const [currentStoreIndex, setCurrentStoreIndex] = useState(0)
  const [isPosDropdownOpen, setIsPosDropdownOpen] = useState(false)
  const [isCmsDropdownOpen, setIsCmsDropdownOpen] = useState(false)
  const [syncedStores, setSyncedStores] = useState([])
  const [isSyncedStoresModalOpen, setIsSyncedStoresModalOpen] = useState(false)
  const [loadedPosStore, setLoadedPosStore] = useState(null)
  const [loadedCmsStore, setLoadedCmsStore] = useState(null)
  const [matchingCompleted, setMatchingCompleted] = useState(false)

  const {
    onPostLocationMatches,
    loading,
    error,
    success
  } = usePostLocationMatches()

  const isMounted = useRef(true)

  useEffect(() => {
    isMounted.current = true

    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    setStoresLeft(pinflagStores.length)
  }, [pinflagStores, setStoresLeft])

  useEffect(() => {
    if (posStores.length === 0 || cmsStores.length === 0) {
      setMatchingCompleted(true)
      onMatchingFinished()
    }
  }, [posStores, cmsStores, onMatchingFinished])

  useEffect(() => {
    const foundLoadedPosStore = posStores.find(
      (store) => String(store.id) === selectedPinflagStore?.posId
    )

    if (foundLoadedPosStore && !selectedPosStore) {
      setLoadedPosStore(foundLoadedPosStore)
      setSelectedPosStore(foundLoadedPosStore)
    }
    const foundLoadedCmsStore = cmsStores.find(
      (store) => String(store.id) === selectedPinflagStore?.cmsId
    )

    if (foundLoadedCmsStore && !selectedCmsStore) {
      setLoadedCmsStore(foundLoadedCmsStore)
      setSelectedCmsStore(foundLoadedCmsStore)
    }
  }, [
    locations,
    pinflagStores,
    posStores,
    cmsStores,
    setStoresLeft,
    selectedPinflagStore,
    setSelectedPinflagStore,
    setSelectedPosStore,
    setSelectedCmsStore,
    selectedPosStore,
    selectedCmsStore
  ])

  // eslint-disable-next-line
  const handleSubmitStoreMatch = async () => {
    if (matchingCompleted) {
      onMatchingFinished()
      return
    }

    const creationData = {
      matchData: [{
        cmsId: selectedCmsStore.id,
        posId: selectedPosStore.id,
        storeId: selectedPinflagStore.id
      }]
    }

    await onPostLocationMatches(companyId, creationData)

    if (!isMounted.current) return

    setStoresLeft(storesLeft - 1)

    setSyncedStores([...syncedStores, {
      pinflag: selectedPinflagStore,
      pos: selectedPosStore,
      cms: selectedCmsStore
    }])

    if (currentStoreIndex < pinflagStores.length - 1) {
      setCurrentStoreIndex(currentStoreIndex + 1)
      setSelectedPinflagStore(pinflagStores[currentStoreIndex + 1])
    } else {
      setMatchingCompleted(true)
      onMatchingFinished()
    }

    const updatedPosStores = posStores.filter((store) => store.id !== selectedPosStore.id)
    const updatedCmsStores = cmsStores.filter((store) => store.id !== selectedCmsStore.id)

    setPosStores(updatedPosStores)
    setCmsStores(updatedCmsStores)

    setSelectedPosStore(updatedPosStores[0])
    setSelectedCmsStore(updatedCmsStores[0])

    if (updatedPosStores.length === 0 || updatedCmsStores.length === 0) {
      setMatchingCompleted(true)
      onMatchingFinished()
    }
  }

  const handleSelectPosStore = (store) => {
    setSelectedPosStore(store)
    setIsPosDropdownOpen(false)
  }

  const handleSelectCmsStore = (store) => {
    setSelectedCmsStore(store)
    setIsCmsDropdownOpen(false)
  }

  const handleShowSyncedStoresModal = () => {
    setIsSyncedStoresModalOpen(true)
  }

  const handleCloseSyncedStoresModal = () => {
    setIsSyncedStoresModalOpen(false)
  }

  return (
    <div className="flex flex-col h-screen items-center justify-start">
      {(loading && <RawLoader />)}
      {(error && <LoadingError />)}
      {success && <div className="bg-green-100 text-green-700 p-4 rounded-lg mb-4">Tienda sincronizada correctamente</div>}
      <div className="flex flex-col justify-center items-center mb-4">
        <img src={pointsActive} alt="pointsActive" className="h-16" />
        <p>Te queda{storesLeft === 1 ? '' : 'n' } {storesLeft} tienda{storesLeft === 1 ? '' : 's' } por sincronizar</p>
        {syncedStores.length > 0 && (
          <Button
            color="bg-white"
            textColor="text-blue-500"
            border=""
            onClick={() => handleShowSyncedStoresModal()}
          >
            Ver tiendas sincronizadas ({syncedStores.length})
          </Button>
        )}

      </div>

      <div className="flex flex-col justify-center items-center w-[60vw] px-4">
        <div className="w-5/6">
          {/* Pseudo Headers */}
          <div className="flex flex-row w-full p-2 text-sm font-semibold">
            <div className="flex-1 text-center">Nombre</div>
            <div className="flex-1 text-center">Comuna</div>
            <div className="flex-1 text-center">Direccion</div>
          </div>

          {/* Data Row */}
          <div className="flex items-center w-full h-12">
            <div className="flex-shrink-0 w-20 flex items-center justify-center pr-5 relative overflow-visible">
              <img src={LOGOS.logoFlat} alt="Logo" className="h-5 w-16"/>
            </div>

            <div className="flex bg-white text-dark-grey shadow-lg rounded-lg w-full border border-gray-200 h-full text-xs font-normal">
              <div className="flex-1 flex items-center justify-center">{selectedPinflagStore?.name}</div>
              <div className="flex-1 flex items-center justify-center border-l border-gray-300">{selectedPinflagStore?.cityName}</div>
              <div className="flex-1 flex items-center justify-center border-l border-gray-300">{selectedPinflagStore?.address}</div>
            </div>
          </div>

          <div className="flex items-center justify-center w-full h-12 text-sm text-dark-grey font-normal mt-2">
            {!matchingCompleted ? 'Verifica los nombres que corresponden en cada canal' : 'No hay más tiendas disponibles para sincronizar'}
          </div>

          {/* Pos Search Button */}
          <div className={`transition-all duration-300 ${isPosDropdownOpen ? 'mb-40' : 'mb-0'}`}>
            <StoreSearchButton
              stores={posStores}
              selectedStore={selectedPosStore}
              handleSelectStore={handleSelectPosStore}
              placeholder={loadedPosStore ? loadedPosStore.name : 'Selecciona la tienda POS'}
              isDropdownOpen={isPosDropdownOpen}
              toggleDropdown={() => setIsPosDropdownOpen(!isPosDropdownOpen)}
              logoSrc={STOCKS_ICONS.bsale}
            />
          </div>

          {/* Cms Search Button */}
          <div className={`transition-all duration-300 ${isCmsDropdownOpen ? 'mb-40' : 'mb-0'}`}>
            <StoreSearchButton
              stores={cmsStores}
              selectedStore={selectedCmsStore}
              handleSelectStore={handleSelectCmsStore}
              placeholder={loadedCmsStore ? loadedCmsStore.name : 'Selecciona la tienda CMS'}
              isDropdownOpen={isCmsDropdownOpen}
              toggleDropdown={() => setIsCmsDropdownOpen(!isCmsDropdownOpen)}
              logoSrc={STOCKS_ICONS.shopify}
            />
          </div>

          <div className="z-0 flex align-center justify-end mt-4">
            <Button
              color="bg-normal-pinflag"
              onClick={handleSubmitStoreMatch}
              className="bg-normal-pinflag text-white p-2 rounded-lg mt-4"
              disabled={matchingCompleted || (!selectedPosStore || !selectedCmsStore)}
            >
              {matchingCompleted ? 'Siguiente paso' : 'Sincronizar'}
            </Button>
          </div>
        </div>
      </div>
      <Modal show={isSyncedStoresModalOpen} handleClose={handleCloseSyncedStoresModal}>
        <div className="p-4">
          <h2 className="text-lg font-semibold text-ultra-dark-grey">Tiendas sincronizadas</h2>
          <div className="mt-4 overflow-auto">
            <table className="min-w-full border border-gray-200">
              <thead>
                <tr className="bg-gray-100">
                  <th className="py-2 px-4 border-b font-semibold text-left">Pinflag</th>
                  <th className="py-2 px-4 border-b font-semibold text-left">POS</th>
                  <th className="py-2 px-4 border-b font-semibold text-left">CMS</th>
                </tr>
              </thead>
              <tbody>
                {syncedStores.map((store) => (
                  <tr key={store.pinflag.id} className="border-b">
                    <td className="py-2 px-4">{store.pinflag.name}</td>
                    <td className="py-2 px-4">{store.pos.name}</td>
                    <td className="py-2 px-4">{store.cms.name}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default StoreMatching
