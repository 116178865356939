import React from 'react'

import { STORE_ICONS } from '../../../../constants/icons'

import StoreCapacityChart from './StoreCapacityChart'

const StoreCapacity = ({ store }) => (
  <>
    <div className="flex text-md text-ultra-dark-grey mb-4 items-center">
      <img src={STORE_ICONS.capacity} alt="store-logo" className="h-5 w-5 mr-2" />
      <div>Capacidad</div>
    </div>
    <div className="flex flex-col items-center justify-center">
      <StoreCapacityChart
        data={[
          { name: 'Ocupado', value: store.PickupPointWarehouse.packagesCount, fill: '#00FF00' },
          { name: 'Disponible', value: store.PickupPointWarehouse.capacity - store.PickupPointWarehouse.packagesCount, fill: '#E0E0E0' }
        ]}
        total={store.PickupPointWarehouse.capacity}
      />
      <div className="flex flex-col justify-center items-center">
          <div className="text-xl text-ultra-dark-grey mb-4">
            {store.PickupPointWarehouse.packagesCount} / {store.PickupPointWarehouse.capacity}
          </div>
      </div>
    </div>
  </>
)

export default StoreCapacity
