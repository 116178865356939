import React from 'react'

import { TableRow } from '../../../shared/Table/Table'
import {
  STOCK_QUANTITY,
  STOCK_SRC,
  STOCK_STATUS,
  STOCK_STATUS_COLORS,
  STOCK_STATUS_TRANSLATE
} from '../../../../constants/stocks'

const StockRow = ({ stock, headers, securityStock }) => (
  <TableRow
    items={headers.map((header) => {
      const value = stock[header.value]

      if (header.value === STOCK_SRC) {
        return (
          <img
            src={value}
            alt="Imagen"
            className="w-8 h-8 object-cover rounded-full"
          />
        )
      }

      if (header.value === STOCK_STATUS) {
        const { backgroundColor, textColor } = STOCK_STATUS_COLORS[value]

        return (
          <div
            className="inline-flex rounded-full px-2 py-1 text-xs text-center items-center justify-center px-3"
            style={{
              backgroundColor,
              color: textColor,
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {STOCK_STATUS_TRANSLATE[value]}
          </div>
        )
      }

      if (header.value === STOCK_QUANTITY) {
        return <div>{value < securityStock ? value : value - securityStock}</div>
      }

      return value
    })}
    onClick={null}
  />
)


export default StockRow
