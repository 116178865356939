import React from 'react'

import { removeSecondsFromString } from '../../../../utils/dates'

const OrderScheduleRow = ({ day, schedule }) => (
  <>
    <div
      className={`text-sm ${schedule?.open ? 'text-ultra-dark-grey' : 'text-gray-400'}`}
    >
      {day}
    </div>
    {schedule?.open ? (
      <>
        <div className="text-sm text-gray-400">
          {removeSecondsFromString(schedule?.startTime || '-')}
        </div>
        <div className="flex justify-center">
          <div className="text-sm text-ultra-dark-grey">-</div>
        </div>
        <div className="text-sm text-gray-400">
          {removeSecondsFromString(schedule?.finishTime || '-')}
        </div>
      </>
    ) : (
      <>
        <div />
        <div />
        <div />
      </>
    )}
  </>
)

export default OrderScheduleRow
