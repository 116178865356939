import React from 'react'

import { STORE_ICONS } from '../../../../constants/icons'

import OrderScheduleRow from './StoreScheduleRow'
import {
  FRIDAY,
  FRIDAY_CODE,
  MONDAY,
  MONDAY_CODE,
  SATURDAY,
  SATURDAY_CODE,
  SUNDAY,
  SUNDAY_CODE,
  THURSDAY,
  THURSDAY_CODE,
  TUESDAY,
  TUESDAY_CODE,
  WEDNESDAY,
  WEDNESDAY_CODE
} from '../../../../constants/days'
import { findSchedule } from '../../../../helpers/warehouse'

const StoreSchedule = ({ store }) => {
  const mondaySchedule = findSchedule(store.schedules, MONDAY)
  const tuesdaySchedule = findSchedule(store.schedules, TUESDAY)
  const wednesdaySchedule = findSchedule(store.schedules, WEDNESDAY)
  const thursdaySchedule = findSchedule(store.schedules, THURSDAY)
  const fridaySchedule = findSchedule(store.schedules, FRIDAY)
  const saturdaySchedule = findSchedule(store.schedules, SATURDAY)
  const sundaySchedule = findSchedule(store.schedules, SUNDAY)


  const orderedSchedules = [
    { day: MONDAY_CODE, schedule: mondaySchedule },
    { day: TUESDAY_CODE, schedule: tuesdaySchedule },
    { day: WEDNESDAY_CODE, schedule: wednesdaySchedule },
    { day: THURSDAY_CODE, schedule: thursdaySchedule },
    { day: FRIDAY_CODE, schedule: fridaySchedule },
    { day: SATURDAY_CODE, schedule: saturdaySchedule },
    { day: SUNDAY_CODE, schedule: sundaySchedule }
  ]

  return (
    <>
      <div className="text-md flex text-ultra-dark-grey mb-4 items-center">
        <img src={STORE_ICONS.schedule} alt="store-logo" className="h-5 w-5 mr-2" />
        <div>Horario</div>
      </div>
      <div className="grid grid-cols-4 gap-y-4">
        {orderedSchedules.map(({ day, schedule }) => (
          <OrderScheduleRow
            key={day}
            day={day}
            schedule={schedule}
          />
        ))}
      </div>
    </>
  )
}

export default StoreSchedule
