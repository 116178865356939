import React from 'react'

import { capitalizeFirstLetter } from '../../../utils/strings'
import { downArrow } from '../../../constants/icons'

const CheckColumn = ({ isOpen, open, name, checkList, handleCheck, checkState, allowSingle }) => {
  if (allowSingle && checkList.length < 1) return <div />
  if (!allowSingle && checkList.length < 2) return <div />

  return (
    <div>
      <button onClick={open} type="button" className="flex font-medium justify-between w-full">
        <div className="flex gap-2">
          {name}
          {checkState.filter((check) => check).length > 0 && (
            <div className="bg-ultra-dark-grey text-white rounded-md px-1.5 py-0.5 text-xs">
              {checkState.filter((check) => check).length}
            </div>
          )}
        </div>
        <img src={downArrow} alt="abrir" className={`w-2.5 ${isOpen ? 'rotate-180' : ''}`} />
      </button>
      {isOpen &&
        checkList.map((checkItem, index) => (
          <label
            key={checkItem}
            htmlFor={checkItem}
            className="mt-2 text-medium-dark-grey flex flex-nowrap whitespace-nowrap cursor-pointer text-s"
          >
            <input
              className="mr-1 cursor-pointer"
              type="checkbox"
              name={checkItem}
              id={checkItem}
              value={checkItem}
              onChange={() => handleCheck(index)}
              checked={checkState[index]}
            />
            {capitalizeFirstLetter(checkItem)}
          </label>
        ))}
    </div>
  )
}

export default CheckColumn
