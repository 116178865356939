import React, { useState } from 'react'
import moment from 'moment'

import { exportRefunds } from '../../helpers/request/refunds'
import { exportIcon } from '../../constants/icons'
import { REFUND_STATUSES } from '../../constants/refunds'
import Button from '../shared/Button'
import Modal from '../shared/Modal/Modal'
import RawLoader from '../shared/Loader/RawLoader'
import CheckColumn from '../Sales/SalesList/SalesFilters/CheckColumn'

import ResponseModal from '../shared/ResponseModal/ResponseModal'
import ToolTip from '../shared/ToolTip'

const ExportRefunds = ({ filtersData }) => {
  const [isOpen, setIsOpen] = useState(false)
  const status = REFUND_STATUSES.map((state) => state.name)
  const couriers = filtersData.couriers || []

  const [checkStatus, setCheckStatus] = useState(new Array(status.length).fill(false))
  const [checkCouriers, setCheckCouriers] = useState(new Array(couriers.length).fill(false))

  const today = new Date()
  const [initialDate, setInitialDate] = useState('')
  const [finalDate, setFinalDate] = useState(today)

  const [isLoadingExport, setIsLoadingExport] = useState(false)
  const [responseModalOpen, setResponseModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isErrorSubmit, setIsErrorSubmit] = useState(false)

  const [email, setEmail] = useState('')
  const [step, setStep] = useState(0)
  const [refundExported, setRefundExported] = useState(0)

  const handleChecks = {
    status: [checkStatus, setCheckStatus],
    courier: [checkCouriers, setCheckCouriers]
  }

  const handleChangeInitialDate = (event) => {
    setInitialDate(event.target.value)
  }

  const handleChangeFinalDate = (event) => {
    const lastDate = event.target.value

    if (lastDate === '') {
      setFinalDate(today)
    } else {
      setFinalDate(lastDate)
    }
  }

  const activeChecks = (checksState) => (
    !checksState.every((value) => value === true) && !checksState.every((value) => value === false)
  )

  const handleChecked = (position, checkState, setCheckState) => {
    const updatedCheckedState = checkState.map((check, index) =>
      index === position ? !check : check
    )

    setCheckState(updatedCheckedState)
  }

  const handleDate = () => {
    const body = { email }

    if (initialDate !== '') body.startDate = moment(initialDate).format('DD-MM-YYYY')

    if (moment(finalDate).format('DD-MM-YYYY') !== moment(today).format('DD-MM-YYYY')) {
      body.finishDate = moment(finalDate).format('DD-MM-YYYY')
    }

    return body
  }

  const makeBody = () => {
    const body = handleDate()

    const refundStatus = REFUND_STATUSES.map((statusItem) => statusItem.value)
    if (activeChecks(checkStatus)) {
      const filtered = refundStatus.filter((_id, index) => checkStatus[index])

      body.refundStatus = filtered.flat()
    } else {
      body.refundStatus = refundStatus.flat()
    }

    if (activeChecks(checkCouriers)) {
      const filtered = couriers.filter((_courier, index) => checkCouriers[index])

      body.courierNames = filtered.flat()
    } else {
      body.courierNames = couriers.flat()
    }

    return body
  }

  const handleSubmit = async () => {
    setIsLoadingExport(true)
    const body = makeBody()
    try {
      const refundCount = await exportRefunds(body)
      setRefundExported(refundCount)
    } catch (errorSubmit) {
      setIsErrorSubmit(true)
      setErrorMessage(
        errorSubmit.response?.data?.message || JSON.stringify(errorSubmit.response || errorSubmit)
      )
    } finally {
      setStep(0)
      setResponseModalOpen(true)
      setIsOpen(false)
      setIsLoadingExport(false)
    }
  }

  return (
    <>
      <ToolTip
        backgroundColor="bg-ultra-dark-grey"
        textColor="text-white"
        hoverElement={
          <button
            className="p-0.5 min-w-[1.75rem] rounded border hover:bg-semi-light-grey"
            onClick={() => setIsOpen(true)}
            type="button"
          >
            <img src={exportIcon} alt="exportar" className="m-1.5 w-3" />
          </button>
        }
        right
      >
        Exportar
      </ToolTip>
      <Modal show={isOpen} handleClose={() => setIsOpen(false)}>
        <div className="font-medium text-ultra-dark-grey text-lg">Exportar indemnizaciones</div>
        {step === 0 ? (
          <div className="flex flex-col items-center text-sm">
            <div className="flex w-fit max-w-xs flex-wrap max-h-[20vh] overflow-auto lg:max-w-none lg:flex-nowrap">
              <CheckColumn
                name="Estados:"
                checkList={status}
                handleCheck={(position) => handleChecked(position, ...handleChecks.status)}
                checkState={checkStatus}
              />
              <CheckColumn
                name="Courier:"
                checkList={couriers}
                handleCheck={(position) => handleChecked(position, ...handleChecks.courier)}
                checkState={checkCouriers}
              />
            </div>
            <div className="flex gap-2 items-center my-4 pb-3">
              <span className="text-ultra-dark-grey font-medium">Fecha:</span>
              <input
                className="border rounded py-1 text-xs text-medium-dark-grey"
                type="date"
                name="initialDate"
                placeholder="Desde"
                max={finalDate}
                value={initialDate}
                onChange={handleChangeInitialDate}
              />
              <input
                className="border rounded py-1 text-xs text-medium-dark-grey"
                type="date"
                name="finalDate"
                placeholder="Hasta"
                max={today}
                min={initialDate}
                value={finalDate}
                onChange={handleChangeFinalDate}
              />
            </div>
            {isLoadingExport ? (
              <RawLoader />
            ) : (
              <Button color="bg-normal-pinflag" onClick={() => setStep(1)}>
                Continuar
              </Button>
            )}
          </div>
        ) : (
          <>
            <div className="my-8 grid grid-cols-3">
              <div>Exportar a</div>
              <input
                onChange={(event) => setEmail(event.target.value)}
                className="border border-medium-grey rounded-sm py-1 px-2 col-span-2 text-sm lg:w-60"
                type="email"
                placeholder="Correo electrónico"
                value={email}
              />
            </div>
            <div className="my-2 flex gap-2 justify-center">
              {isLoadingExport ? (
                <RawLoader />
              ) : (
                <>
                  <Button color="bg-dark-grey" onClick={() => setStep(0)}>
                    Volver
                  </Button>
                  <Button color="bg-normal-pinflag" onClick={handleSubmit}>
                    Exportar
                  </Button>
                </>
              )}
            </div>
          </>
        )}
      </Modal>
      <ResponseModal
        handleClose={() => setResponseModalOpen(false)}
        isModalOpen={responseModalOpen}
        successMessage={
          refundExported < 3000
            ? `Se han exportado ${refundExported} indemnizaciones.`
            : `Indemnizaciones exportadas a ${email}.`
        }
        error={isErrorSubmit}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default ExportRefunds
