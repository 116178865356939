import React, { useState } from 'react'
import ResponseModal from '../../shared/ResponseModal/ResponseModal'
import SelectionButton from '../../shared/SelectionButton'
import SelectionBar from '../../shared/SelectionBar/SelectionBar'
import { SALES_SELECTION_ICONS } from '../../../constants/icons'
import { exportRefunds } from '../../../helpers/request/refunds'

const RefundsSelection = ({
  selectedRefunds,
  handleCloseBar
}) => {
  const [isLoadingExport, setIsLoadingExport] = useState(false)
  const [responseModalOpen, setResponseModalOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState(false)
  const [errorResponse, setErrorResponse] = useState(false)

  const parseBodyExport = () => {
    const refundsIds = selectedRefunds.map((packageId) => packageId)

    return { refundsIds }
  }

  const exportSelectedRefunds = async () => {
    if (selectedRefunds.length === 0) return

    setIsLoadingExport(true)
    const body = parseBodyExport()

    try {
      await exportRefunds(body)
    } catch (errorSubmit) {
      setErrorMessage(JSON.stringify(errorSubmit.response || errorSubmit))
      setResponseModalOpen(true)
      setErrorResponse(true)
    } finally {
      setIsLoadingExport(false)
    }
  }

  const handleClose = () => {
    setResponseModalOpen(false)
  }

  return (
    <>
      <SelectionBar
        amount={selectedRefunds.length}
        text="Indemnizaciones"
        color="bg-white"
        handleClose={handleCloseBar}
      >
        <SelectionButton
          text={
            <>
              <p>Exportar</p>
              <p>Indemnizaciones</p>
            </>
          }
          icon={SALES_SELECTION_ICONS.export}
          handleClick={exportSelectedRefunds}
          isLoading={isLoadingExport}
        />
      </SelectionBar>
      <ResponseModal
        handleClose={errorResponse ? handleClose : () => window.location.reload()}
        isModalOpen={responseModalOpen}
        error={errorResponse}
        errorMessage={errorMessage}
      />
    </>
  )
}

export default RefundsSelection
