import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage'

import { ACTIONS } from '../../../../constants/icons'
import ActionsPopUp from '../../../shared/ActionsPopUp/ActionsPopUp'
import { Table, TableRow } from '../../../shared/Table/Table'
import ToggleSwitch from '../../../shared/ToggleSwitch/ToggleSwitch.style'
import { COLORS } from '../../../../constants/styles'
import ROUTES from '../../../../constants/routes'
import ConfirmModal from '../../../shared/ConfirmModal/ConfirmModal'
import ResponseModal from '../../../shared/ResponseModal/ResponseModal'
import { RULE_TOGGLE } from '../../../../constants/urls'
import ActionItem from '../../../shared/ActionsPopUp/ActionItem'
import randomApi from '../../../../axiosConfig/randomApi'
import { arrayToObject } from '../../../../utils/arrays'
import { getStates } from '../../../../helpers/request/states'
import useFetch from '../../../../hooks/useFetchParams'

import RuleDetailModal from './RuleDetailModal'
import RulesChip from './RulesChip'

const RulesTable = ({ rules, isLoading, setRules, error, page, pageSize }) => {
  const [openModal, setOpenModal] = useState(false)
  const [selectedRule, setSelectedRule] = useState({})
  const [loadingToggleChange, setLoadingToggleChange] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [errorChangeRule, setErrorChangeRule] = useState(false)
  const [ruleToChange, setRuleToChange] = useState({})
  const [states, setStates] = useState([])
  const [conflictErrorMessage, setConflictErrorMessage] = useState(null)

  const currentRules = rules.slice(pageSize * (page - 1), pageSize * page)

  const companies = secureLocalStorage.getItem('companies')
    ? arrayToObject(secureLocalStorage.getItem('companies'), 'name')
    : {}

  useFetch(getStates, setStates)

  const navigate = useNavigate()

  const handleCloseModal = () => {
    setOpenModal(false)
  }

  const toggleRuleSwitch = (newRule) => {
    const newRules = rules
    const globalIndex = ruleToChange.index + pageSize * (page - 1)
    newRules[globalIndex] = newRule
    setRules(newRules)
  }

  const handleOpenModal = (rule) => {
    setSelectedRule(rule)
    setOpenModal(true)
  }

  const parseConflictErrorMessage = (responseData) => {
    const message = 'La regla a activar tiene conflictos con las siguientes reglas:'
    const conflictNames = responseData.conflicts.map((conflict) => conflict.name)

    return `${message}\n${conflictNames.join(', ')}`
  }

  const handleToggleChange = async () => {
    setLoadingToggleChange(true)
    try {
      const response = await randomApi().put(RULE_TOGGLE(ruleToChange.ruleId))
      if (response.data.conflicts) {
        setConflictErrorMessage(parseConflictErrorMessage(response.data))
        setErrorChangeRule(true)
      } else {
        toggleRuleSwitch(response.data)
      }
    } catch {
      setErrorChangeRule(true)
    } finally {
      setOpenConfirmModal(false)
      setLoadingToggleChange(false)
    }
  }

  const handleClose = () => {
    setRuleToChange('')
    setOpenConfirmModal(false)
  }

  const handleOpen = (rule) => {
    setRuleToChange(rule)
    setOpenConfirmModal(true)
  }

  const handleCloseErrorModal = () => {
    setErrorChangeRule(false)
    setConflictErrorMessage(null)
  }

  if (!isLoading && !error && rules.length === 0) {
    return (
      <div className="mx-10 border-x border-b text-center py-8 bg-white text-s">
        No hay reglas con este criterio
      </div>
    )
  }

  return (
    <>
      <Table
        headers={['Empresa', 'Nombre', 'Condiciones', 'Acción', 'Estado', 'Acción']}
        isLoading={isLoading}
        error={error}
        minHeight
      >
        {currentRules?.map((rule, index) => (
          <TableRow
            key={rule.id}
            items={[
              companies[rule.companyId],
              rule.name,
              <div className="flex gap-2 flex-wrap">
                {rule.conditions.price && <RulesChip condition="Precio" />}
                {rule.conditions.weight && <RulesChip condition="Peso" />}
                {rule.conditions.deliveryType && <RulesChip condition="Tipo de Envío" />}
                {rule.conditions.serviceType && <RulesChip condition="Tipo de Servicio" />}
                <RulesChip condition="Zona" />
              </div>,
              <div className="chips-space">
                {rule.outcome.price !== null && <div className="action">Tarifa</div>}
                {rule.outcome.courier !== null && <div className="action">Asignar Courier</div>}
                {rule.outcome?.deliveryType &&
                  Object.values(rule.outcome.deliveryType).some((delivery) => !delivery) && (
                    <div className="action">Deshabilitar envío</div>
                  )}
                {rule.outcome?.serviceType &&
                  Object.values(rule.outcome.serviceType).some((service) => !service) && (
                    <div className="action">Deshabilitar servicio</div>
                  )}
              </div>,
              <div>
                <ToggleSwitch
                  inactiveColor={COLORS.LIGHT_GREY}
                  check={rule.enable}
                  setCheck={() => handleOpen({ ruleId: rule.id, index })}
                  size="small"
                />
              </div>,
              <div className="relative">
                <ActionsPopUp>
                  <ActionItem
                    icon={ACTIONS.viewDetails}
                    action={() => handleOpenModal(rule)}
                    text="Ver Detalle"
                  />
                  <ActionItem
                    icon={ACTIONS.editRule}
                    action={() => navigate(`${ROUTES.RULES}/${rule.id}`)}
                    text="Editar"
                  />
                </ActionsPopUp>
              </div>
            ]}
          />
        ))}
      </Table>
      <RuleDetailModal
        handleClose={handleCloseModal}
        openModal={openModal}
        rule={selectedRule}
        states={Object.values(states) || []}
      />
      <ConfirmModal
        handleClose={handleClose}
        isModalOpen={openConfirmModal}
        handleConfirm={handleToggleChange}
        loading={loadingToggleChange}
      >
        Confirma si deseas activar/desactivar la regla.
      </ConfirmModal>
      <ResponseModal
        isModalOpen={errorChangeRule}
        handleClose={handleCloseErrorModal}
        error={error || conflictErrorMessage !== null}
        errorHasData={conflictErrorMessage !== null}
        errorMessage={conflictErrorMessage}
      />
    </>
  )
}

export default RulesTable
