import { useState } from 'react'

import { createStores } from '../../../helpers/request/stores'

const useCreateStores = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const onCreateStores = async (data) => {
    setLoading(true)
    try {

      await createStores(data)

      setError(false)
      setSuccess(true)
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  return { onCreateStores, loading, error, success }
}

export default useCreateStores
