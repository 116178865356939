import React from 'react'

import {
  identifierCreator,
  saleStatusColor,
  statusPointColor,
  statusPointText
} from '../../../../helpers/sales'
import { getFormattedDate } from '../../../../utils/dates'
import { capitalizeFirstLetter, truncateString, acronymGenerator } from '../../../../utils/strings'
import { Table, TableRow } from '../../../shared/Table/Table'
import { DELIVERY_TYPES, PACKAGE_TYPES } from '../../../../constants/sales'
import { PACKAGE_STATUS_TRANSLATE } from '../../../../constants/packageStatus'
import { COURIER_SERVICES_ICONS, TICKET_ICONS } from '../../../../constants/icons'
import ToolTip from '../../../shared/ToolTip'
import ClipBoardText from '../../../shared/ClipboardText'

import Actions from './Actions'
import FixOrderWithErrorButton from './FixOrderWithErrorButton'

const SalesTable = ({
  sales,
  selectedSales,
  setSelectedSales,
  setSelectedToOwnPoint,
  pageSize,
  page,
  isLoading,
  removeSales,
  showArchivedSales,
  packageType,
  error,
  handleSort
}) => {
  const totalHealthySales = sales.filter((sale) => !sale.errorMessage).length
  const checkAll = Object.keys(selectedSales).length === Math.min(pageSize, totalHealthySales) &&
  totalHealthySales !== 0

  const handleSelectedOwnPoint = (sale, check) => {
    if (sale.deliveryType !== 'storePickup') return
    if (check) {
      setSelectedToOwnPoint((salesOwnPoint) =>
        salesOwnPoint.filter(
          (item) => item !== `${identifierCreator(sale.companyName)}-${sale.codbultocliente}`
        )
      )
    } else {
      setSelectedToOwnPoint((prevSelected) => [
        ...prevSelected,
        `${identifierCreator(sale.companyName)}-${sale.codbultocliente}`
      ])
    }
  }

  const isChecked = (sale) => selectedSales.includes(sale.idbulto)

  const changeSelected = (sale) => {
    handleSelectedOwnPoint(sale, isChecked(sale))
    if (!isChecked(sale)) {
      setSelectedSales((prevSelected) => [...prevSelected, sale.idbulto])
    } else {
      setSelectedSales(selectedSales.filter((item) => item !== sale.idbulto))
    }
  }

  const handleCheckAll = () => {
    if (!checkAll) {
      setSelectedSales(sales.map((sale) => {
        if (sale.errorMessage) return null

        return sale.idbulto
      }).filter(Boolean))

      const salesOwnPoint = sales.filter((sale) => sale.deliveryType === 'storePickup')
      setSelectedToOwnPoint(
        salesOwnPoint.map(
          (sale) => `${identifierCreator(sale.companyName)}-${sale.codbultocliente}`
        )
      )
    } else {
      setSelectedSales([])
      setSelectedToOwnPoint([])
    }
  }

  if (!isLoading && !error && sales.length === 0) {
    return (
      <div className="mx-10 border text-center py-8 bg-white text-s">
        No hay ventas con este criterio
      </div>
    )
  }

  const initialColumns = (sale) =>
    packageType === PACKAGE_TYPES[2]
      ? []
      : [capitalizeFirstLetter(packageType === PACKAGE_TYPES[0] ? sale.courierName : sale.channel)]

  const packageTypeColumns = (sale) => {
    const columns = initialColumns(sale)

    if (packageType === PACKAGE_TYPES[0]) {
      columns.push(DELIVERY_TYPES[sale.deliveryType])

      columns.push(
        <div className="flex gap-2">
          <img
            src={COURIER_SERVICES_ICONS[sale.courierService]}
            className="my-auto h-auto w-auto max-w-[1rem] max-h-[1rem]"
            alt=""
          />
          {capitalizeFirstLetter(sale.courierService)}
        </div>
      )

      return columns
    }

    columns.push(truncateString(sale.customerName, 20))
    columns.push(sale.shippingMode)

    return columns
  }

  const desarchivedColumns = (sale) =>
    showArchivedSales
      ? ['']
      : [
          <ToolTip
            backgroundColor="bg-dark-pinflag"
            textColor="text-white"
            hoverElement={
              <div className={`flex gap-2 rounded-full w-max p-1 ${statusPointColor(sale)}`} />
            }
            right
          >
            <div className="whitespace-normal">{statusPointText(sale)}</div>
          </ToolTip>,
          !sale.errorMessage ?
            <input type="checkbox" checked={isChecked(sale)} onChange={() => changeSelected(sale)} /> :
            ['']
        ]

  const packageTypeHeaders = () => {
    if (packageType === PACKAGE_TYPES[0]) {
      return ['Courier', 'Envío', 'Servicio']
    }

    if (packageType === PACKAGE_TYPES[1]) {
      return ['Canal', 'Cliente', 'Modo de Envío']
    }

    return []
  }

  const sortValues = {
    'Fecha Compra': 'FECHACOMPRA',
    'Estado': 'status'
  }

  const tableHeaders = [
    'O. Venta',
    'Estado',
    'Fecha Compra',
    ...packageTypeHeaders(),
    'Punto',
    'Región',
    'Acción'
  ]

  return (
    <Table
      maxHeight="max-h-[70vh]"
      sortValues={sortValues}
      sortData={handleSort}
      headers={[
        '',
        <ToolTip
          backgroundColor="bg-dark-pinflag"
          textColor="text-white"
          right
          hoverElement={<img src={TICKET_ICONS.ticketBlue} className="w-4" alt="" />}
        >
          <div className="w-28 text-xs whitespace-normal">
            Estas ventas corresponden a los últimos 3 meses
          </div>
        </ToolTip>,
        ...(showArchivedSales
          ? tableHeaders
          : [
              <input type="checkbox" checked={checkAll} onChange={handleCheckAll} />,
              ...tableHeaders
            ])
      ]}
      isLoading={isLoading}
      error={error}
      minHeight
    >
      {sales?.map((sale, index) => (
        <TableRow
          key={sale.idbulto || sale.codbultocliente}
          onClick={ !sale.errorMessage ? () => changeSelected(sale) : null }
          items={[
            sale.errorMessage ?
            <FixOrderWithErrorButton
              up={index >= sales.length - 2 && sales.length > 3}
              status={sale.status}
              deliveryType={sale.deliveryType}
              orderId={sale.idbulto}
              saleEditableData={{
                address: sale.addressErrorData.address,
                deliveryInstructions: sale.addressErrorData.instructions,
                state: sale.stateName,
                cityId: sale.addressErrorData.cityId,
                cityName: sale.city,
                errorMessage: sale.errorMessage,
                customer: {
                  name: sale.addressErrorData.name,
                  lastname: sale.addressErrorData.lastName,
                  email: sale.addressErrorData.email,
                  phone: sale.addressErrorData.phone,
                  ssn: sale.addressErrorData.ssn || null
                }
              }}
            /> :
            <div />,
            ...desarchivedColumns(sale),
            <div>
              {identifierCreator(sale.companyName)}-
              <ClipBoardText text={sale.codbultocliente} />
            </div>,
            <div className={`w-fit px-2 py-1 rounded ${saleStatusColor(sale.status)}`}>
              {PACKAGE_STATUS_TRANSLATE[sale.status]}
            </div>,
            getFormattedDate(sale.fechacompra),
            ...packageTypeColumns(sale),
            <div className=''>
              {acronymGenerator(sale.nombrebodega)}
            </div>,
            <ToolTip
              hoverElement={sale.stateCode}
              backgroundColor="bg-ultra-dark-grey"
              textColor="text-white"
              up={index === sales.length - 1}
            >
              {sale.stateName}
            </ToolTip>,
            <div className="relative">
              <Actions
                up={index >= sales.length - 2 && sales.length > 3}
                packageId={sale.idbulto || sale.codbultocliente}
                pinflagId={sale.codbultogenvisible}
                page={page}
                pageSize={pageSize}
                storePickup={sale.deliveryType === 'storePickup'}
                status={sale.status}
                showArchivedSales={showArchivedSales}
                removeSales={removeSales}
                packageType={packageType}
                active={sale.addressErrorData}
                packageWithError={sale.status === 'with_error'}
              />
            </div>
          ]}
        />
      ))}
    </Table>
  )
}

export default SalesTable
