/* eslint-disable react-func/max-lines-per-function */
import { useEffect, useState } from 'react'

import randomApi from '../axiosConfig/randomApi'

const useFetch = (url, setData, params) => {
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    const controller = new AbortController()
    const { signal } = controller

    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await randomApi().get(url, { ...params, signal })

        setData(response.data)
        setIsLoading(false)
      } catch {
        if (!controller.signal.aborted) {
          setError(true)
          setIsLoading(false)
        }
      }
    }

    fetchData()

    return () => {
      controller.abort()
      setIsLoading(true)
      setError(false)
    }
  }, [params, setData, url])

  return { isLoading, setIsLoading, error, setError }
}

export default useFetch
