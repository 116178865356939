import { ASSETS_URL } from './others'

export const TICKET_STATES_INDEX = {
  Creado: 1,
  'En revisión': 2,
  Gestionado: 3,
  Resuelto: 4
}

export const CREATION_STEPS = [ 'Motivo', 'Detalle', 'Resumen' ]

export const ticketTypes = {
  preShipping: [
    {
      text: 'Pedido atrasado',
      name: 'Pedido / Pedido con demora',
      icon: `${ASSETS_URL}/tickets/reason-delay.svg`
    },
    {
      text: 'Cambiar dirección',
      name: 'Pedido / Cambiar destino',
      icon: `${ASSETS_URL}/tickets/reason-change-address.svg`
    }
  ],
  postShipping: [
    {
      text: 'Pedido dañado',
      name: 'Indemnizaciones / Entrega con daño',
      icon: `${ASSETS_URL}/tickets/reason-damaged.svg`
    },
    {
      text: 'Devolución remitente',
      name: 'Pedido / Devolución a remitente',
      icon: `${ASSETS_URL}/tickets/reason-return.svg`
    }
  ],
  others : [
    {
      text: 'Plataforma',
      name: 'Otros / Falla en plataforma',
      icon: `${ASSETS_URL}/filters/tickets/managed-active-icon.svg`
    },
    {
      text: 'Pago',
      name: 'Otros / Problema en pago',
      icon: `${ASSETS_URL}/tickets/reason-payment.svg`
    },
    {
      text: 'Otro',
      name: 'Otros / Otras consultas',
      icon: `${ASSETS_URL}/tickets/reason-others.svg`
    }
  ]
}

export const TICKETS_STATUS = [
  {
    value: 1,
    value_for_export: 'Creado',
    name: 'Creada'
  },
  {
    value: 2,
    value_for_export: 'En revisión',
    name: 'En revisión'
  },
  {
    value: 3,
    value_for_export: 'Gestionado',
    name: 'Gestionada'
  },
  {
    value: 4,
    value_for_export: 'Resuelto',
    name: 'Resuelta'
  }
]

export const TICKETS_REASONS = [
  {
    value: 'Pedido / Pedido con demora',
    name: 'Pedido con demora'
  },
  {
    value: 'Pedido / Pedido perdido',
    name: 'Pedido perdido'
  },
  {
    value: 'Pedido / Dirección incorrecta',
    name: 'Dirección incorrecta'
  },
  {
    value: 'Pedido / Devolución a remitente',
    name: 'Devolución a remitente'
  },
  {
    value: 'Pedido / Cambiar destino',
    name: 'Cambiar destino'
  },
  {
    value: 'Pedido / Problemas con retiro',
    name: 'Problemas con retiro'
  },
  {
    value: 'Pedido / Agendar Retiro',
    name: 'Agendar retiro'
  },
  {
    value: 'Pedido / Desconocimiento entrega',
    name: 'Desconocimiento entrega'
  },
  {
    value: 'Indemnizaciones / Pieza faltante',
    name: 'Pieza faltante'
  },
  {
    value: 'Indemnizaciones / Entrega con daño',
    name: 'Entrega con daño'
  },
  {
    value: 'Otros / Falla en plataforma',
    name: 'Falla con plataforma'
  },
  {
    value: 'Otros / Otras consultas',
    name: 'Otras consultas'
  },
  {
    value: 'Otros / Problema en pago',
    name: 'Problema en pago'
  }
]

export const STATUS_COLOR = {
  Creado: 'text-[#9747FF] bg-[#F7F0FF]',
  'En revisión': 'text-[#F0A155] bg-[#FFF2DF66]',
  Gestionado: 'text-[#0E9AFF] bg-[#0E9AFF14]',
  Resuelto: 'text-[#4EB03E] bg-[#34C24014]'
}

export const STATUS_ICONS = {
  Creado: `${ASSETS_URL}/tickets/created.png`,
  'En revisión': `${ASSETS_URL}/tickets/inReview.svg`,
  Gestionado: `${ASSETS_URL}/tickets/managed.png`,
  Resuelto: `${ASSETS_URL}/tickets/solved.png`
}
