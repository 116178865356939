import React, { useState } from 'react'

import ToggleSwitch from '../../shared/ToggleSwitch/ToggleSwitch.style'
import { COLORS } from '../../../constants/styles'
import { DEFAULT_STORE_SCHEDULES } from '../../../constants/stores'

const ScheduleCreate = ({ setSchedules }) => {
  const [scheduleList, setScheduleList] = useState(DEFAULT_STORE_SCHEDULES)

  const handleScheduleChange = (index, field, value) => {
    const newSchedules = [...scheduleList]
    newSchedules[index] = { ...newSchedules[index], [field]: value }
    setScheduleList(newSchedules)
    setSchedules(newSchedules)
  }

  return (
    <div className="mt-2">
      <div className="grid grid-cols-3 gap-3 mb-2">
        <div />
        <div className="text-sm text-gray-500">Apertura</div>
        <div className="text-sm text-gray-500">Cierre</div>
      </div>
      {scheduleList.map((schedule, index) => (
        <div key={schedule.dayCode} className="grid grid-cols-3 gap-1 mb-3 items-center">
          <div className="flex justify-center items-center gap-2">
            <span className="text-sm text-gray-700">{schedule.dayCode}</span>
            <ToggleSwitch
              size="ultra-small"
              inactiveColor={COLORS.LIGHT_GREY}
              check={schedule.open}
              setCheck={(value) => handleScheduleChange(index, 'open', value)}
              className="flex items-center"
            />
          </div>
          {schedule.open && (
            <>
              <select
                className="p-2 border rounded-md text-sm w-full"
                value={schedule.startTime}
                onChange={(e) => handleScheduleChange(index, 'startTime', e.target.value)}
                disabled={!schedule.open}
              >
                {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
                  <option key={hour} value={`${hour.toString().padStart(2, '0')}:00`}>
                    {`${hour.toString().padStart(2, '0')}:00`}
                  </option>
                ))}
              </select>
              <select
                className="p-2 border rounded-md text-sm w-full"
                value={schedule.finishTime}
                onChange={(e) => handleScheduleChange(index, 'finishTime', e.target.value)}
                disabled={!schedule.open}
              >
                {Array.from({ length: 24 }, (_, i) => i).map((hour) => (
                  <option key={hour} value={`${hour.toString().padStart(2, '0')}:00`}>
                    {`${hour.toString().padStart(2, '0')}:00`}
                  </option>
                ))}
              </select>
            </>
          )}
        </div>
      ))}
    </div>
  )
}

export default ScheduleCreate
