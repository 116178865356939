import React from 'react'

import { Table } from '../../../shared/Table/Table'
import Pagination from '../../../shared/Pagination/Pagination'
import { sortArrayByKey } from '../../../../utils/arrays'
import {
  STOCK_IMAGE_HEADER,
  STOCK_NAME,
  STOCK_NAME_HEADER,
  STOCK_QUANTITY,
  STOCK_QUANTITY_HEADER,
  STOCK_RESERVED,
  STOCK_RESERVED_HEADER,
  STOCK_SKU,
  STOCK_SKU_HEADER,
  STOCK_SRC,
  STOCK_STATUS,
  STOCK_STATUS_HEADER
} from '../../../../constants/stocks'
import { NUMBER, STRING } from '../../../../constants/data_types'

import StockRow from './StockRow'

const StoreStocksList = ({
  stocks,
  setStocks,
  searchResult,
  setSearchResult,
  page,
  setPage,
  pageSize,
  setPageSize,
  securityStock
}) => {
  if (!searchResult) {
    return (
      <div className="h-64 flex justify-center items-center">
        <h1 className="text-2xl font-bold text-gray-500">No hay existencias</h1>
      </div>
    )
  }
  if (!searchResult.length) {
    return (
      <div className="h-64 flex justify-center items-center">
        <h1 className="text-2xl font-bold text-gray-500">No hay existencias para los filtros seleccionados</h1>
      </div>
    )
  }

  const headers = [
    { name: STOCK_SKU_HEADER, value: STOCK_SKU, type: STRING },
    { name: STOCK_NAME_HEADER, value: STOCK_NAME, type: STRING },
    { name: STOCK_STATUS_HEADER, value: STOCK_STATUS, type: STRING},
    { name: STOCK_QUANTITY_HEADER, value: STOCK_QUANTITY, type: NUMBER },
    { name: STOCK_RESERVED_HEADER, value: STOCK_RESERVED, type: NUMBER },
    { name: STOCK_IMAGE_HEADER, value: STOCK_SRC, type: STRING }
  ]

  const handleSort = (sortValues, order) => {
    const sortedSearchedStocks = sortArrayByKey(searchResult, sortValues, order)

    setSearchResult(sortedSearchedStocks)

    const sortedStocks = sortArrayByKey(stocks, sortValues, order)

    setStocks(sortedStocks)
  }

  const currentStocks = searchResult.slice((page - 1) * pageSize, page * pageSize)

  return (
    <>
      <Table
        headers={headers.map((header) => header.name)}
        sortValues={Object.fromEntries(headers.map((header) =>
          [header.name, { key: header.value, type: header.type }]
        ))}
        sortData={handleSort}
        isLoading={false}
        error={false}
        roundedTop
        fullWidth={1}
      >
        {currentStocks.map((stock) => (
          <StockRow
            key={stock.id}
            stock={stock}
            headers={headers}
            securityStock={securityStock}
            />
          ))}
      </Table>
      <div className="h-16 flex place-content-end mx-10">
        <Pagination
          page={page}
          setPage={setPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          itemsCount={searchResult.length}
        />
      </div>
    </>
  )
}

export default StoreStocksList
