import randomApi from '../../axiosConfig/randomApi'

export const getStores = async (params) => {
  const response = await randomApi().get('/warehouses', params)

  return response.data
}

export const getStore = async (config) => {
  const { params: storeId } = config

  const response = await randomApi().get(`/warehouses/${storeId}`)

  return response.data
}

export const createStores = async (data) => {
  const response = await randomApi().post('/warehouses/stores/create', data)

  return response.data
}
