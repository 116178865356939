import React from 'react'

import { COLORS } from '../../../../constants/styles'

const OrderScheduleRowEdit = ({ day, dayState, setDayState, setIsUpdated }) => {
  const handleChange = (value) => {
    setIsUpdated(true)
    setDayState(value)
  }

  const changeStartTime = (event) => {
    handleChange((prevStatus) => ({
      ...prevStatus,
      startTime: event.target.value
    }))
  }

  const changeFinishTime = (event) =>
    handleChange((prevStatus) => ({
      ...prevStatus,
      finishTime: event.target.value
    }))

  return (
    <div className="flex items-center space-x-4 mb-2">
      <input
        type="checkbox"
        checked={dayState?.open}
        onChange={() =>
          handleChange((prevStatus) => ({
            ...prevStatus,
            open: !prevStatus.open
          }))
        }
        className="w-4 h-4 border-gray-300 rounded focus:ring-0"
        style={{
          accentColor: COLORS.LIGHT_GREY,
          color: COLORS.LIGHT_GREY
        }}
      />
      <span className={`w-12 ${!dayState?.open ? 'text-gray-400' : ''}`}>{day}</span>
      <input
        className={`w-20 px-2 py-1 text-sm border rounded-md ${!dayState?.open ? 'text-gray-400 bg-gray-50' : ''}`}
        type="time"
        value={dayState?.startTime?.slice(0, 5) || '09:00'}
        onChange={changeStartTime}
        disabled={!dayState?.open}
      />
      <span className={`${!dayState?.open ? 'text-gray-400' : ''}`}>-</span>
      <input
        className={`w-20 px-2 py-1 text-sm border rounded-md ${!dayState?.open ? 'text-gray-400 bg-gray-50' : ''}`}
        type="time"
        value={dayState?.finishTime?.slice(0, 5) || '19:00'}
        onChange={changeFinishTime}
        disabled={!dayState?.open}
      />
    </div>
  )
}

export default OrderScheduleRowEdit
