import React, { useState } from 'react'

import { downArrow } from '../../../../constants/icons'

import StoreLocation from './StoreLocation'
import StoreCapacity from './StoreCapacity'
import StoreSchedule from './StoreSchedule'
import StoreDimensions from './StoreDimensions'

const StoreDetailsCard = ({ store }) => {
  const [isCardExpanded, setIsCardExpanded] = useState(true)

  const toggleCardExpansion = () => {
    setIsCardExpanded(prev => !prev)
  }

  return (
    <div className="flex flex-col w-full bg-light-grey">
      <div
        className={`overflow-hidden transition-max-height duration-300 ease-in-out ${isCardExpanded ? 'max-h-screen' : 'max-h-0'}`}
      >
        {isCardExpanded && (
          <div className="flex flex-row py-8 items-start space-x-6 h-96">
            <div className="w-1/5 flex flex-col bg-white rounded-sm border border-gray-200 p-4 h-full justify-start items-center">
              <StoreCapacity store={store} />
            </div>
            <div className="w-1/5 flex flex-col bg-white rounded-sm border border-gray-200 p-4 h-full justify-start">
              <StoreDimensions store={store} />
            </div>
            <div className="w-1/5 flex flex-col bg-white rounded-sm border border-gray-200 p-4 h-full justify-start items-center ">
              <StoreSchedule store={store} />
            </div>
            <div className="w-2/5 flex flex-col bg-white rounded-sm border border-gray-200 p-4 h-full justify-start">
              <StoreLocation store={store} />
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-row py-4 px-4 items-center justify-center">
        <div
          className="h-4 flex flex-row items-center justify-center cursor-pointer"
          onClick={toggleCardExpansion}
          role="button"
          tabIndex="0"
        >
          <img alt="expand-toggle-arrow" src={downArrow} className={`h-4 w-4 ${isCardExpanded ? 'rotate-180' : ''}`} />
          <div className="mx-2">{isCardExpanded ? 'Ver Menos' : 'Ver Más'}</div>
        </div>
      </div>
    </div>
  )
}

export default StoreDetailsCard
