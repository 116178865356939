import apiClient from '../../axiosConfig/apiClient'
import { TICKETS_URL } from '../../constants/urls'
import { downloadFileUrl } from '../../utils/files'

export const addImageToTicket = async (ticketId, formData) => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  }

  const response = await apiClient.post(`${TICKETS_URL}/${ticketId}/image`, formData, config)

  return response.data
}

export const exportTickets = async (body) => {
  const response = await apiClient.post('/companies/tickets/xlsx', body)

  downloadFileUrl(response.data.url)

  return 3000
}
