import React from 'react'

import { STORE_ICONS } from '../../../../constants/icons'

const StoreLocation = ({ store }) => (
  <>
    <div className="flex text-md text-ultra-dark-grey mb-4 items-center">
      <img src={STORE_ICONS.location} alt="store-logo" className="h-5 w-5 mr-2" />
      <div>Ubicación</div>
    </div>
    <div className="flex flex-col">
      <div className="text-sm text-ultra-dark-grey mb-1">Dirección</div>
      <div className="text-sm text-gray-400 mb-4">{store.Location.address}</div>
    </div>
    <div className="flex flex-col">
      <div className="text-sm text-ultra-dark-grey mb-1">Comuna</div>
      <div className="text-sm text-gray-400 mb-4">{store.Location.comuna.nombrecomuna}</div>
    </div>
    <div className="flex flex-col">
      <div className="text-sm text-ultra-dark-grey mb-1">Región</div>
      <div className="text-sm text-gray-400 mb-4">{store.Location.comuna.Region.nombreregion}</div>
    </div>
    <div className="flex flex-col">
      <div className="text-sm text-ultra-dark-grey mb-1">Instrucciones</div>
      <div className="text-sm text-gray-400 mb-4">{store.Location.instructions}</div>
    </div>
  </>
)

export default StoreLocation
