import React, { useState } from 'react'
import secureLocalStorage from 'react-secure-storage'

import Modal from '../../shared/Modal/Modal'
import ResponseModal from '../../shared/ResponseModal/ResponseModal'
import AddStores from '../../../pages/Settings/Stocks/AddStores'
import Button from '../../shared/Button'

import useCreateStores from './useCreateStores'
import ScheduleCreate from './ScheduleCreate'

const AddStoreModal = ({
  companyId,
  open,
  setOpen,
  states
}) => {
  const [openCreateSingleStoreModal, setOpenCreateSingleStoreModal] = useState(false)
  const [openCreateMultipleStoresModal, setOpenCreateMultipleStoresModal] = useState(false)
  const [selectedCompanyId, setSelectedCompanyId] = useState('')
  const [selectedCompany, setSelectedCompany] = useState('')
  const [storeIdToCreate, setStoreIdToCreate] = useState('')
  const [storeNameToCreate, setStoreNameToCreate] = useState('')
  const [storeAddressToCreate, setStoreAddressToCreate] = useState('')
  const [storeAddress2ToCreate, setStoreAddress2ToCreate] = useState('')
  const [selectedState, setSelectedState] = useState('')
  const [selectedStateId, setSelectedStateId] = useState('')
  const [selectedCity, setSelectedCity] = useState('')
  const [latitude, setLatitude] = useState('')
  const [longitude, setLongitude] = useState('')
  const [schedules, setSchedules] = useState([])
  const [aisles, setAisles] = useState(2)
  const [columns, setColumns] = useState(2)
  const [height, setHeight] = useState(2)
  const [capacity, setCapacity] = useState(35)

  const companies = secureLocalStorage.getItem('companies')

  const {
    onCreateStores,
    error: errorCreateStores,
    success: successCreateStores
  } = useCreateStores()

  // eslint-disable-next-line
  const handleSubmitSingleStore = async () => {
    const storeData = {
      storesData: [{
        companyId: selectedCompanyId,
        storeId: storeIdToCreate,
        warehouseName: storeNameToCreate,
        address: storeAddressToCreate,
        instructions: storeAddress2ToCreate,
        cityName: selectedCity,
        stateId: selectedStateId,
        capacity,
        aisles,
        columns,
        levels: height,
        latitude,
        longitude,
        schedules: schedules.map((schedule) => ({
          day: schedule.day,
          open: schedule.open,
          startTime: schedule.startTime,
          finishTime: schedule.finishTime
        }))
      }]
    }

    await onCreateStores(storeData)

    if (!errorCreateStores) {
      setOpenCreateSingleStoreModal(false)
    }
  }

  const handleStateChange = (event) => {
    const stateName = event.target.value
    setSelectedState(stateName)
    setSelectedStateId(states[stateName].stateId)
    setSelectedCity(null)
  }

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value)
  }

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value)
    setSelectedCompanyId(companies.find((company) => company.name === event.target.value).id)
  }

  const handleOpenCreateSingleStoreModal = () => {
    setOpenCreateSingleStoreModal(true)
  }

  const handleOpenCreateMultipleStoresModal = () => {
    setOpenCreateMultipleStoresModal(true)
  }
  return (
    <div>
      <Modal
        show={open}
        handleClose={() => setOpen(false)}
        title="Crear tienda"
      >
        <div className="flex w-full justify-center p-8 mt-4 border-t">
          <button
            type="button"
            className="flex border rounded-lg bg-white p-4 mx-4"
            onClick={handleOpenCreateSingleStoreModal}
          >
            Única
          </button>
          <button
            type="button"
            className="flex border rounded-lg bg-white p-4 mx-4"
            onClick={handleOpenCreateMultipleStoresModal}
          >
            Múltiples
          </button>
        </div>
      </Modal>
      <Modal
        show={openCreateSingleStoreModal}
        handleClose={() => setOpenCreateSingleStoreModal(false)}
        title="Crear tienda"
      >
        <form className="flex flex-col p-8 w-[600px] mx-12 justify-center items-center">
          <div className="flex flex-col space-y-4 w-3/4">
            <div className="text-xl text-ultra-dark-grey">Empresa</div>
            <select
              className="border border-grey p-2 rounded-lg"
              onChange={handleCompanyChange}
              value={selectedCompany || ''}
            >
              <option value="" disabled>Seleccione una empresa</option>
              {companies.map((company) => (
                <option key={company.id} value={company.name}>
                  {company.name}
                </option>
              ))}
            </select>
            <div className="text-xl text-ultra-dark-grey">Dirección</div>
            <div className="text-sm text-dark-grey">Región</div>
            <select
              className="border border-grey p-2 rounded-lg"
              onChange={handleStateChange}
              value={selectedState || ''}
            >
              <option value="" disabled>Seleccione una región</option>
              {Object.values(states).map((state) => (
                <option key={state.stateId} value={state.stateId}>
                  {state.name}
                </option>
              ))}
            </select>
            <div className="text-sm text-dark-grey">Comuna</div>
            <select
              className="border border-grey p-2 rounded-lg"
              onChange={handleCityChange}
              value={selectedCity || ''}
              disabled={!selectedState}
            >
              <option value="" disabled>Seleccione una comuna</option>
              {selectedState &&
                states[selectedState]?.cities.map((city) => (
                  <option key={city.id} value={city.id}>
                    {city.name}
                  </option>
                ))
              }
            </select>
            <div className="text-sm text-dark-grey">Dirección</div>
            <input
              type="text"
              placeholder="Dirección"
              className="border border-grey p-2 rounded-lg"
              value={storeAddressToCreate}
              onChange={(e) => setStoreAddressToCreate(e.target.value)}
            />
            <div className="text-sm text-dark-grey">Instrucciones</div>
            <textarea
              placeholder="Instrucciones (Dirección 2)"
              className="border border-grey p-2 rounded-lg"
              value={storeAddress2ToCreate}
              onChange={(e) => setStoreAddress2ToCreate(e.target.value)}
            />
            <div className="text-sm text-dark-grey">Coordenadas - Latitud</div>
            <input
              type="text"
              placeholder="Latitud"
              className="border border-grey p-2 rounded-lg"
              value={latitude}
              onChange={(e) => setLatitude(e.target.value)}
            />
            <div className="text-sm text-dark-grey">Coordenadas - Longitud</div>
            <input
              type="text"
              placeholder="Longitud"
              className="border border-grey p-2 rounded-lg"
              value={longitude}
              onChange={(e) => setLongitude(e.target.value)}
            />
            <div className="text-xl text-ultra-dark-grey">Horario</div>

            <div className="w-4/5">
              <ScheduleCreate setSchedules={setSchedules} />
            </div>

            <div className="text-xl text-ultra-dark-grey">Bodega</div>
            <div className="text-sm text-dark-grey">Nombre</div>
            <input
              type="text"
              placeholder="Nombre"
              className="border border-grey p-2 rounded-lg"
              value={storeNameToCreate}
              onChange={(e) => setStoreNameToCreate(e.target.value)}
            />
            <div className="text-sm text-dark-grey">ID</div>
            <input
              type="text"
              placeholder="ID Tienda"
              className="border border-grey p-2 rounded-lg"
              value={storeIdToCreate}
              onChange={(e) => setStoreIdToCreate(e.target.value)}
            />
            <div className="flex gap-4">
              <div className="flex flex-col w-1/3">
                <div className="text-sm text-dark-grey">Pasillos</div>
                <input
                  type="number"
                  placeholder="Pasillos"
                  className="border border-grey p-2 rounded-lg"
                  value={aisles}
                  onChange={(e) => setAisles(e.target.value)}
                />
              </div>
              <div className="flex flex-col w-1/3">
                <div className="text-sm text-dark-grey">Columnas</div>
                <input
                  type="number"
                  placeholder="Columnas"
                  className="border border-grey p-2 rounded-lg"
                  value={columns}
                  onChange={(e) => setColumns(e.target.value)}
                />
              </div>
              <div className="flex flex-col w-1/3">
                <div className="text-sm text-dark-grey">Pisos</div>
                <input
                  type="number"
                  placeholder="Pisos"
                  className="border border-grey p-2 rounded-lg"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                />
              </div>
            </div>
            <div className="text-xl text-ultra-dark-grey">Capacidad máxima</div>
            <input
              type="number"
              placeholder="Capacidad"
              className="border border-grey p-2 rounded-lg w-1/3"
              value={capacity}
              onChange={(e) => setCapacity(e.target.value)}
            />
          </div>
          <div className="flex justify-end mt-4">
            <Button
              color="bg-normal-pinflag"
              textColor="text-white"
              onClick={handleSubmitSingleStore}
            >
              Guardar
            </Button>
          </div>
        </form>
      </Modal>
      <AddStores
        open={openCreateMultipleStoresModal}
        setOpen={setOpenCreateMultipleStoresModal}
        companyId={companyId}
      />
      {errorCreateStores && (
        <ResponseModal
          title="Error al crear tienda"
          isModalOpen={errorCreateStores}
          handleClose={() => window.location.reload()}
          errorMessage="Ha ocurrido un error al crear la tienda."
        />
      )}
      {successCreateStores && (
        <ResponseModal
          isModalOpen={successCreateStores}
          handleClose={() => window.location.reload()}
          successMessage="La tienda ha sido creada exitosamente."
        />
      )}
    </div>
  )
}

export default AddStoreModal
