export const MONDAY = 'monday'
export const TUESDAY = 'tuesday'
export const WEDNESDAY = 'wednesday'
export const THURSDAY = 'thursday'
export const FRIDAY = 'friday'
export const SATURDAY = 'saturday'
export const SUNDAY = 'sunday'

export const MONDAY_CODE = 'Lun'
export const TUESDAY_CODE = 'Mar'
export const WEDNESDAY_CODE = 'Mie'
export const THURSDAY_CODE = 'Jue'
export const FRIDAY_CODE = 'Vie'
export const SATURDAY_CODE = 'Sab'
export const SUNDAY_CODE = 'Dom'

export const DAYS_TRANSLATIONS = {
  [MONDAY]: 'Lunes',
  [TUESDAY]: 'Martes',
  [WEDNESDAY]: 'Miercoles',
  [THURSDAY]: 'Jueves',
  [FRIDAY]: 'Viernes',
  [SATURDAY]: 'Sabado',
  [SUNDAY]: 'Domingo'
}
