import { DATE, NUMBER, STRING } from '../constants/data_types'

export const arrayToObject = (array, key) =>
  array.reduce((object, item) => {
    const newObject = object
    newObject[item.id] = item[key]
    return newObject
  }, {})

export const copyArray = (array) => JSON.parse(JSON.stringify(array))

export const compareItemsByString = (a, b, key, order) => {
  if (order === 'asc') {
    return (String(a[key]) || '-').localeCompare(String(b[key]) || '-')
  }
  return (String(b[key]) || '-').localeCompare(String(a[key]) || '-')
}

export const compareItemsByNumber = (a, b, key, order) => {
  if (order === 'asc') {
    return Number(a[key]) - Number(b[key])
  }
  return Number(b[key]) - Number(a[key])
}

export const compareItemsByDate = (a, b, key, order) => {
  if (order === 'asc') {
    return new Date(a[key]) - new Date(b[key])
  }
  return new Date(b[key]) - new Date(a[key])
}

export const sortArrayByKey = (array, sortValues, order) => {
  const { key, type } = sortValues

  const copiedArray = copyArray(array)

  const sorted = copiedArray.sort((a, b) => {
    if (type === STRING) return compareItemsByString(a, b, key, order)
    if (type === NUMBER) return compareItemsByNumber(a, b, key, order)
    if (type === DATE) return compareItemsByDate(a, b, key, order)
    return 0
  })
  return sorted
}

export const getArrayFromObject = (obj, key, name) => {
  if (name) return obj.filter((data) => data[key] === name)
  return obj.map((data) => data[key])
}
