import React from 'react'

import { TableRow } from '../../shared/Table/Table'
import { STORE_STATUS, STORE_STATUS_COLORS, STORE_STATUS_TRANSLATE } from '../../../constants/stores'

const StoreRow = ({ store, headers, handleSelectStore }) => (
  <TableRow
    items={headers.map((header) => {
      const value = store[header.value]

      if (header.value === STORE_STATUS) {
        const { backgroundColor, textColor } = STORE_STATUS_COLORS[store.status]

        return (
          <div
            className="inline-flex rounded-full px-2 py-1 text-xs text-center items-center justify-center px-3"
            style={{
              backgroundColor,
              color: textColor,
              maxWidth: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {STORE_STATUS_TRANSLATE[store.status]}
          </div>
        )
      }

      return value
    })}
    onClick={() => handleSelectStore(store)}
  />
)

export default StoreRow
