import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import SearchBar from '../../shared/SearchBar'
import { Table } from '../../shared/Table/Table'
import Pagination from '../../shared/Pagination/Pagination'
import { isSubstring } from '../../../utils/strings'
import { searchTermsGenerator } from '../../../utils/filters'
import ROUTES from '../../../constants/routes'
import { sortArrayByKey } from '../../../utils/arrays'
import {
  STORE_ID,
  STORE_ID_HEADER,
  STORE_NAME,
  STORE_NAME_HEADER,
  STORE_REGION,
  STORE_REGION_HEADER,
  STORE_STATUS,
  STORE_STATUS_HEADER,
  STORE_STOCK,
  STORE_STOCK_HEADER
} from '../../../constants/stores'
import { NUMBER, STRING } from '../../../constants/data_types'

import StoreRow from './StoreRow'
import StoresFilters from './StoresFilters'

const StoresTable = ({
  stores,
  setStores,
  searchResult,
  setSearchResult,
  isLoading,
  error
} = {}) => {
  const [searchBarInput, setSearchBarInput] = useState('')
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [filteredStores, setFilteredStores] = useState([])
  const [isFiltered, setIsFiltered] = useState(false)

  const navigate = useNavigate()

  const tableHeaders = [
    { name: STORE_ID_HEADER, value: STORE_ID, type: STRING },
    { name: STORE_NAME_HEADER, value: STORE_NAME, type: STRING },
    { name: STORE_STATUS_HEADER, value: STORE_STATUS, type: STRING },
    { name: STORE_STOCK_HEADER, value: STORE_STOCK, type: NUMBER },
    { name: STORE_REGION_HEADER, value: STORE_REGION, type: STRING }
  ]

  const searchBarFilter = (storesList, searchTerm) => {
    setSearchBarInput(searchTerm)
    if (searchTerm !== '') {
      const searchTerms = searchTermsGenerator(searchTerm)
      const searchedStores = storesList.filter(
        (store) => searchTerms.some((term) => (
          isSubstring(store.name, term) ||
          isSubstring(store.companyName, term) ||
          isSubstring(store.address, term) ||
          isSubstring(store.city, term) ||
          isSubstring(store.state, term)
        ))
      )
      setSearchResult(searchedStores)
    } else {
      setSearchResult(storesList)
    }
  }

  const searchHandler = (searchTerm) => {
    setSearchBarInput(searchTerm)
    const storesList = isFiltered ? filteredStores : stores
    searchBarFilter(storesList, searchTerm)
  }

  const handleFilterChange = (storesList) => {
    setFilteredStores(storesList)
    searchBarFilter(storesList, searchBarInput)
  }

  const handleSelectStore = (store) => {
    navigate(`${ROUTES.STORES}/${store.warehouseId}`)
  }

  const handleSort = (sortValues, order) => {
    const sortedSearchedStores = sortArrayByKey(searchResult, sortValues, order)

    setSearchResult(sortedSearchedStores)

    const sortedStores = sortArrayByKey(stores, sortValues, order)

    setStores(sortedStores)
  }

  const currentStores = searchResult.slice((page - 1) * pageSize, page * pageSize)
  return (
    <div className="w-full">
      <div className="mt-4 mx-10 border-x border-t rounded-t justify-stretch flex bg-white px-3 py-2">
        <div className="flex space-x-4">
          <StoresFilters
            stores={stores}
            handleFilterChange={handleFilterChange}
            isFiltered={isFiltered}
            setIsFiltered={setIsFiltered}
          />
          <SearchBar
            searchbarInput={searchBarInput}
            placeholder="Buscar tienda"
            searchKeyword={searchHandler}
          />
        </div>
      </div>
      {searchResult.length === 0 && !isLoading && !error ? (
        <div className="mx-10 border rounded-b text-center py-8 bg-white text-s">
          No hay tiendas actualmente con los filtros seleccionados.
        </div>
      ) : (
        <>
          <Table
            maxHeight="max-h-[70vh]"
            headers={tableHeaders.map((header) => header.name)}
            sortValues={Object.fromEntries(tableHeaders.map((header) =>
              [header.name, { key: header.value, type: header.type }]
            ))}
            sortData={handleSort}
            isLoading={isLoading}
            error={error}
            minHeight
          >
            {currentStores.map((store) => (
              <StoreRow
                key={store.id}
                store={store}
                headers={tableHeaders}
                handleSelectStore={handleSelectStore}
              />
            ))}
          </Table>
          <div className="h-16 flex place-content-end mx-10">
            <Pagination
              page={page}
              setPage={setPage}
              pageSize={pageSize}
              setPageSize={setPageSize}
              itemsCount={searchResult.length}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default StoresTable
